import { Link, useNavigate   } from 'react-router-dom';
import styled from 'styled-components';
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import os_Logo_green from './img/OS_Logo_green.png';

import CypherDudesArchivesArtifact from '../web3/abi/CypherDudesArchives.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const OwnerLink = styled(Link)`
margin-bottom :10px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;

const ArchivesGalleryTokenCard = ({ dudeTokens }) => {

    const [loaded, setLoadState] = useState(false);
    const [currentOwner, setCurrentOwner] = useState({});

    const navigate = useNavigate();

    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider_ = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");

    const cypherDudesArchivesReadContract = new ethers.Contract(
        contractAddresses.CypherDudesArchives,
        CypherDudesArchivesArtifact.abi,
        infuraProvider
    );

    useEffect(() => {
        setLoadState(false);
        getCurrentOwner(dudeTokens.archiveId);
        const archiveName = `cypherCard_${dudeTokens.fileId}`;
        const container = document.getElementById(archiveName).children[0].children[0].children[0].firstChild;
        if (dudeTokens.message.slice(0, 6) === "<head>") {
            const iframeElement = document.createElement('iframe');
            iframeElement.setAttribute('srcdoc', dudeTokens.message);
            iframeElement.style.setProperty('width', '475px');
            iframeElement.style.setProperty('height', '475px');
            iframeElement.style.setProperty('border', 'none');
            container.appendChild(iframeElement);
        } else {
            container.innerHTML = dudeTokens.message;
        }
        setLoadState(true);
    }, []);

    useEffect(() => {
        const archiveName = `cypherCard_${dudeTokens.fileId}`;
        const container = document.getElementById(archiveName).children[0].children[0].children[0].firstChild;
        let scriptList = container.getElementsByTagName('script');
        var newScripts = [];
        for (let i = 0; i < scriptList.length; i++) {
            let content = scriptList[i].textContent;
            scriptList[i].remove();
            let newScript = document.createElement('script');
            newScript.textContent = content;
            newScripts.push(newScript);
        }
        newScripts.forEach((script) => container.lastChild.after(script));
        setLoadState(false);
    }, [loaded]);

    const getAddress = async (address) => {
        let resolvedAddress = await infuraProvider.lookupAddress(address);
        const holder = {
            longAddress: address,
            shortAddress: resolvedAddress === null ? address.slice(0, 6) + "..." + address.slice(-4) : resolvedAddress

        }
        return holder
    }

    const getCurrentOwner = async (id) => {
        try {
            let owner = await cypherDudesArchivesReadContract.ownerOf(id);
            setCurrentOwner(await getAddress(owner));
        } catch (error) {
            console.log(error);
        }
    }

    const checkArchive = () => {
        navigate(`/archive/${dudeTokens.archiveId}`);
    }

    return (
        <div className='tokenBox'>
            <div className='MyArchiveGalleryCard'>
                <svg id={`cypherCard_${dudeTokens.fileId}`} baseProfile="full" viewBox="0 0 475 475" version="1.1" overflow="visible" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsev="http://www.w3.org/2001/xml-events" xmlnsXlink="http://www.w3.org/1999/xlink" >
                    <foreignObject x="0" y="0" width="475px" height="475px">
                        <html xmlns="http://www.w3.org/1999/xhtml">
                            <div style={{ backgroundColor: '#0000FF', width: '100%', fontFamily: 'Andale Mono', fontSize: '7px', color: '#ffffff' }}>
                                <div class="data-container" id="decodedData" style={{ width: 'unset', scrollbarColor: '#00ff00 #ffffff00', scrollbarWidth: 'thin', overflowY: 'auto', wordWrap: 'break-word' }}></div>
                            </div>
                        </html>
                    </foreignObject>
                </svg>


            </div>
            <div className='MainGalleryInfo'>
                <p className='TokenName'>↑</p>
                <p className='TokenName'>{`| Pass: ${dudeTokens.name}`}</p>
                <p className='TokenName'><OwnerLink onClick={checkArchive}>{`| File #${dudeTokens.fileId} - Archive #${dudeTokens.archiveId}`}</OwnerLink></p>
                <p className='TokenName'>{`| Issued : ${new Date(parseInt(dudeTokens.timeStamp) * 1000).toUTCString()}`}</p>
                <div className='actionFooter'>
                    <a target='_blank' href={`https://opensea.io/assets/ethereum/0xe1851e0d09352f2e56171c62c7ded015e199258b/${dudeTokens.archiveId}`} className="uxBtn smBtn" ><div className='logoLinkDiv'><p className='buyBtn'>BUY </p><img className='archiveLogoLink' src={os_Logo_green} alt="Logo" /></div></a>
                    <p className='TokenName'>Owned by : <OwnerLink target="_blank" to={`https://opensea.io/${currentOwner.longAddress}`}>{currentOwner.shortAddress}</OwnerLink></p>
                </div>

            </div>
        </div>
    )
}

export default ArchivesGalleryTokenCard;
