import React from "react";
import Background from "../components/background";
import MediaQuery from 'react-responsive';
import { breakpoints } from "../components/constants";
import { useEffect, useState, useMemo } from "react";
import { ethers } from "ethers";
import { Alchemy, Network} from "alchemy-sdk";
import { contractAddresses } from '../web3/contractsAddresses';
import { alchemySettings } from "../App";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { minidenticon } from 'minidenticons';

const TokenLink = styled(Link)`
  text-decoration: none;
  color : #00ff00;
  cursor: pointer;
  &:focus, &:visited, &:link, &:active {
      text-decoration: none;
  }
`;

const MinidenticonImg = ({ username, saturation, ligthness, ...props }) => {
    const svgURI = useMemo(
        () => 'data:image/svg+xml;utf8,' + encodeURIComponent(minidenticon(username, saturation, ligthness)),
        [username, saturation, ligthness]
    )
    return (<img src={svgURI} alt={username} {...props} />)
}

const LeaderBoard = () => {
    const alchemy = new Alchemy({
        apiKey : process.env.ALCHEMY_ETH_KEY,
        network: Network.ETH_MAINNET,
      });

    const [topHolders, setTopHolders] = useState([]);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider_ = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");
    const openProvider = new ethers.BrowserProvider(window.ethereum);

    useEffect(() => {
        getTopHolders();
    }, []);

    const queryOptions = {
        withTokenBalances: true,
    }
    const mergeBalances = (contract1Data, contract2Data) => {
        const balanceMap = new Map();

        const processOwners = (owners) => {
            owners.forEach(owner => {
                const  {ownerAddress, tokenBalances} = owner;
                const totalBalance = tokenBalances.length;
                console.log(totalBalance)
                if (balanceMap.has(ownerAddress)) {
                    balanceMap.set(ownerAddress, balanceMap.get(ownerAddress)+totalBalance);
                } else {
                    balanceMap.set(ownerAddress, totalBalance);
                }
            });
        };

        processOwners(contract1Data);
        processOwners(contract2Data);

        return Array.from(balanceMap.entries()).map(([ownerAddress, totalBalance])=>({
            ownerAddress,
            totalBalance
        }));
    }

    const fetchTokenHolders = async (contract) => {
        const response = await alchemy.nft.getOwnersForContract(contract, queryOptions);
        return response.owners;
    }

    

    const getTopHolders = async () => {
        const [holders, bitHolders] = await Promise.all([
            fetchTokenHolders(contractAddresses.CypherDudes),
            fetchTokenHolders(contractAddresses.CypherDudesBit)
        ])
        console.log(bitHolders);

        const globalHolders = mergeBalances(holders, bitHolders);

        globalHolders.sort((a, b) => {
            if (a.totalBalance > b.totalBalance) {
                return -1;
            }
            if (a.totalBalance < b.totalBalance) {
                return 1;
            }
            return 0;
        });
        const topHolders = globalHolders.slice(0, 30);
        console.log(topHolders);
        let topHoldersList = [];
        for (let i = 0; i < 30; i++) {
            let resolvedAddress = await infuraProvider.lookupAddress(topHolders[i].ownerAddress);
            const holderInfo = {
                address: resolvedAddress === null ? topHolders[i].ownerAddress : resolvedAddress,
                shortAddress: resolvedAddress === null ? topHolders[i].ownerAddress.slice(0, 6)+"..."+topHolders[i].ownerAddress.slice(-4) : resolvedAddress,
                balance: topHolders[i].totalBalance
            }
            topHoldersList.push(holderInfo);
            setTopHolders([...topHoldersList]);
        }

    }


    return (
        <div>
            <Background />
            <div className="AdminContainer">
                <p className="highlight">Top 30 Cypherdudes Holders</p>
                <table className='addressBox'>
                    <tbody>
                        {topHolders.map((holder, id) => {
                            return <tr key={id}>
                                <td><MinidenticonImg username={holder.address} saturation="100" ligthness="50" width="40" height="40" /></td>
                                <td>
                                    <MediaQuery minWidth={parseInt(breakpoints.md)}>
                                        <TokenLink to={`https://zapper.xyz/account/${holder.address}`} target="_blank">{holder.address}</TokenLink>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={parseInt(breakpoints.md)}>
                                        <TokenLink className="mobileTableAddress" to={`https://zapper.xyz/account/${holder.address}`} target="_blank">{holder.shortAddress}</TokenLink>
                                    </MediaQuery>

                                </td>
                                <td><p className="balance">{holder.balance}</p></td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default LeaderBoard;